import axios from 'axios';
import * as typedefs from "../../typedefs";

/*eslint no-unused-vars: 0*/

class LocationService {

    constructor() {

    }

    /**     
     * @returns {Promise<typedefs.Location[]>}
     */
    getLocations() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'location',
                params: {                    
                }
            }).then((result) => {
                const data = JSON.parse(result.data);
                console.log('location data', data);
                resolve(data);
            })
                .catch(err => {
                    console.log('Error occurred while getting locations', err);
                    reject(err);
                });
        });
    }
}

const locationService = new LocationService();
export { locationService };