import axios from 'axios';
import * as typedefs from "../../typedefs";

/*eslint no-unused-vars: 0*/

class SettingsService {

    constructor() {

    }

    /**
     * @returns {Promise<typedefs.Settings>}
     */
    getSettingss() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'settings',
            }).then((result) => {
                const data = JSON.parse(result.data);
                console.log('settings data', data);
                resolve(data);
            })
                .catch(err => {
                    console.log('Error occurred while getting settings', err);
                    reject(err);
                });
        });
    }
}

const settingsService = new SettingsService();
export { settingsService };