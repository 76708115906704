import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

import ContactArea from "../components/contact/ContactArea";
import CardsArea from "../components/cards/CardArea";
import InvoiceArea from '../components/invoice/InvoiceArea';
import SuccessArea from '../components/final/SuccessArea';

const routes = [
    {
        component: ContactArea,
        name: "contact",
        path: "*",
        meta: { step: "1" }
    },
    {
        component: CardsArea,
        name: "cards",
        path: "/cards",
        meta: { step: "2" }
    },
    {
        component: InvoiceArea,
        name: "invoice",
        path: "/invoice",
        meta: { step: "3" }
    },
    {
        component: SuccessArea,
        name: "success",
        path: "/success",
        meta: { step: "4" }
    },
    {
        component: SuccessArea,
        name: "successnormal",
        path: "/successnormal",
        meta: { step: "4" }
    },
    {
        component: SuccessArea,
        name: "successcontact",
        path: "/successcontact",
        meta: { step: "4" }
    },
    {
        component: SuccessArea,
        name: "successcontactmax",
        path: "/successcontactmax",
        meta: { step: "4" }
    }
];

export default new VueRouter({
    routes
})