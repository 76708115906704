/*eslint-disable no-undef*/
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store';
import i18n from './i18n';
import axios from 'axios';
import router from "./router";
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.use(VueCookies);
Vue.use(VueGtag, {
  config: {
    id: config.GOOGLE_ANALYTICS,
    params: {
      send_page_view: false,
    }
  }
});


Vue.prototype.$http = axios
axios.defaults.baseURL = config.VUE_APP_API;
console.log('Using API URL:' + axios.defaults.baseURL);
i18n.locale = config.VUE_APP_I18N_LOCALE;
i18n.fallbackLocale = config.VUE_APP_I18N_FALLBACK_LOCALE;
console.log('Using locale:' + config.VUE_APP_I18N_LOCALE);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')