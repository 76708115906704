import axios from 'axios';
import * as typedefs from "../../typedefs";
import StringUtils from '../util/stringutils';

/*eslint no-unused-vars: 0*/

class LimitService {

    constructor() {

    }

    /**
     * @param {String} companyType
     * @returns {Promise<{limits: typedefs.Limit[], defaultLimit: String}>}     
     */
    getLimits(companyType) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'limit',
                params: {
                    companyType: companyType
                }
            }).then((result) => {

                /** @type {Array<typedefs.Limit>} */
                const data = JSON.parse(result.data);

                console.log('limits', data);

                data.Limits.forEach(limit => {
                    limit.Name = limit.TransactionsPerWeekDisplay;
                });

                resolve({
                    limits: data.Limits,
                    defaultLimit: data.DefaultLimitCode
                });
            })
                .catch(err => {
                    console.log('Error occurred while getting limits', err);
                    reject(err);
                });
        });
    }
}

const limitService = new LimitService();
export { limitService };