/*eslint no-debugger: 0*/

import Vuex from 'vuex';
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

const persistKey = '1747bbbc-93e7-4471-bd4e-8b70bf50a566';

// Create store
export default new Vuex.Store({
  state: {
    step: 'contact',
    companyTitle: '',
    companyType: '',
    kvkNumber: '',
    establishmentNumber: '',
    customerFirstname: '',
    customerPrefix: '',
    customerLastname: '',
    country: '',
    postcode: '',
    housenr: '',
    city: '',
    street: '',
    tel1: '',
    tel2: '',
    website: '',
    emailaddress: '',
    contactFirstname: '',
    contactPrefix: '',
    contactLastname: '',
    contactEmailAddress: '',
    contactMobilePhone: '',
    contactBirthDate: '',
    requestid: 0,
    defaultcard: null,
    cards: [],
    invoiceEmail: null,
    invoiceByEmail: true,
    mandateType: null,
    mandateBank: null,
    mandateIban: null,
    mandateBic: null,
    mandateFileType: null,
    mandateMaxAmount: 0,
    awaitingMandate: false,
    customCards: [],
    settings: {},
    vatNumber: null,
    preferredLocation: null,
    filledCompany: false,
    filledPostcode: false,
    filledHousenr: false,
    filledCity: false,
    filledStreet: false,
    shorthandRequest: false,
    isMobile: false,
    mandateError: false,
    estimateAmount: 0,
  },
  getters: {
    // Add the `getField` getter to the
    // `getters` of your Vuex store instance.
    getField,
  },
  mutations: {
    // Add the `updateField` mutation to the
    // `mutations` of your Vuex store instance.
    updateField,
    purge(state) {
      window.localStorage.setItem(persistKey, null);

      state.step = 'contact';
      state.companyTitle = '';
      state.companyType = '';
      state.kvkNumber = '';
      state.establishmentNumber = '';
      state.customerFirstname = '';
      state.customerPrefix = '';
      state.customerLastname = '';
      state.country = '';
      state.postcode = '';
      state.housenr = '';
      state.city = '';
      state.street = '';
      state.tel1 = '';
      state.tel2 = '';
      state.website = '';
      state.emailaddress = '';
      state.contactFirstname = '';
      state.contactPrefix = '';
      state.contactLastname = '';
      state.contactEmailAddress = '';
      state.contactMobilePhone = '';
      state.contactBirthDate = '';
      state.requestid = 0;
      state.defaultcard = null;
      state.cards = [];
      state.invoiceEmail = null;
      state.invoiceByEmail = false;
      state.mandateType = null;
      state.mandateBank = null;
      state.mandateIban = null;
      state.mandateBic = null;
      state.mandateFileType = null;
      state.mandateMaxAmount = 0;
      state.awaitingMandate = false;
      state.customCards = [];
      state.vatNumber = '';
      state.preferredLocation = null;
      state.filledCompany = false;
      state.filledPostcode = false;
      state.filledHousenr = false;
      state.filledCity = false;
      state.filledStreet = false;
      state.shorthandRequest = false;
      state.mandateError = false;
      state.estimateAmount = 0;

    },
    persist(state) {
      window.localStorage.setItem(persistKey, JSON.stringify(state));
    },
    rebuild(state) {
      var str = window.localStorage.getItem(persistKey);
      if (str && str !== "null") {
        const data = JSON.parse(str);

        state.step = data.step;
        state.companyTitle = data.companyTitle;
        state.companyType = data.companyType;
        state.kvkNumber = data.kvkNumber;
        state.establishmentNumber = data.establishmentNumber;
        state.customerFirstname = data.customerFirstname;
        state.customerPrefix = data.customerPrefix;
        state.customerLastname = data.customerLastname;
        state.country = data.country;
        state.postcode = data.postcode;
        state.housenr = data.housenr;
        state.city = data.city;
        state.street = data.street;
        state.tel1 = data.tel1;
        state.tel2 = data.tel2;
        state.website = data.website;
        state.emailaddress = data.emailaddress;
        state.contactFirstname = data.contactFirstname;
        state.contactPrefix = data.contactPrefix;
        state.contactLastname = data.contactLastname;
        state.contactEmailAddress = data.contactEmailAddress;
        state.contactMobilePhone = data.contactMobilePhone;
        state.contactBirthDate = data.contactBirthDate;
        state.requestid = data.requestid;
        state.defaultcard = data.defaultcard;
        state.cards = data.cards;
        state.invoiceEmail = data.invoiceEmail;
        state.invoiceByEmail = data.invoiceByEmail;
        state.mandateType = data.mandateType;
        state.mandateBank = data.mandateBank;
        state.mandateIban = data.mandateIban;
        state.mandateBic = data.mandateBic;
        state.mandateFileType = data.mandateFileType;
        state.mandateMaxAmount = data.mandateMaxAmount;
        state.awaitingMandate = data.awaitingMandate;
        state.customCards = data.customCards;
        state.settings = data.settings;
        state.vatNumber = data.vatNumber;
        state.preferredLocation = data.preferredLocation;
        state.filledCompany = data.filledCompany;
        state.filledPostcode = data.filledPostcode;
        state.filledHousenr = data.filledHousenr;
        state.filledCity = data.filledCity;
        state.filledStreet = data.filledStreet;
        state.shorthandRequest = data.shorthandRequest;
        state.mandateError = data.mandateError;
        state.estimateAmount = data.estimateAmount;
      }
    }
  },
  actions: {
    persist(context) {
      context.commit('persist');
    },
    rebuild(context) {
      context.commit('rebuild');
    },
    purge(context) {
      context.commit('purge');
    }
  }
});