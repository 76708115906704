import axios from 'axios';
import * as typedefs from "../../typedefs";
import StringUtils from '../util/stringutils';

/*eslint no-unused-vars: 0*/
/*eslint no-debugger: 0*/

class CardService {

    constructor() {

    }

    /**
     * @param {String} companyType
     * @returns {Promise<{minCards: Number, maxCards: Number}>}
     */
    getNumberOfCards(companyType) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'card',
                params: {
                    companyType: companyType
                }
            }).then((result) => {

                /** @type {Array<typedefs.Limit>} */
                const data = JSON.parse(result.data);
                console.log('cardnumber', data);

                resolve({ minCards: data.MinimumCards, maxCards: data.MaximumCards });
            })
                .catch(err => {
                    console.log('Error occurred while getting limits', err);
                    reject(err);
                });
        });
    }

    /**     
     * @param {Array<typedefs.Card>} cards
     * @param {Array<typedefs.Limit>} limits
     * @param {Array<typedefs.Product>} products
     * @param {typedefs.Settings} settings
     * @returns {{valid: Boolean, index?: Number, error?: string}}
     */
    validateCards(cards, limits, products, settings) {
        const errorResult = (i, str) => {
            return {
                valid: false,
                index: i,
                error: str
            };
        };

        const successResult = () => {
            return {
                valid: true
            }
        };

        if (cards && cards.length) {
            for (let i = 0; i < cards.length; i++) {
                let card = cards[i];
                let cardProducts = card.Products ? card.Products.split(',') : [];
                let hasGas = false;
                let invalidPin = false;

                // Has to have limit selected
                if (!card.LimitSet) {
                    return errorResult(i);
                }

                // At least one product has to be selected
                if (!cardProducts || cardProducts.length === 0) {
                    return errorResult(i);
                }

                // At least one gas product has to be selected
                cardProducts.forEach(cp => {
                    let pr = products.find(p => p.Code == cp);
                    if (pr && pr.Type === 0) {
                        hasGas = true;
                    }
                });

                // Check for invalid PIN codes
                settings.RestrictedPins.forEach(pin => {
                    if (card.PinCode === pin) {
                        invalidPin = true;
                    }
                });

                if (settings.IncludeCardWishPin && settings.PinCodeMandatory && (!card.PinCode || card.PinCode === '')) {
                    return errorResult(i, "requiredpincode");
                }

                if (settings.LicensePlateRequired && (!card.LicensePlate || card.LicensePlate === '')) {
                    return errorResult(i, "requiredlicenseplate");
                }

                if (card.LicensePlate && (card.LicensePlate !== '')) {
                    // Regex with dashes, dutch licenseplate options
                    var reg1  =/^[a-zA-Z]{2}-[\d]{2}-[\d]{2}$/;
                    var reg2  =/^[\d]{2}-[\d]{2}-[a-zA-Z]{2}$/;
                    var reg3  =/^[\d]{2}-[a-zA-Z]{2}-[\d]{2}$/;
                    var reg4  =/^[a-zA-Z]{2}-[\d]{2}-[a-zA-Z]{2}$/;
                    var reg5  =/^[a-zA-Z]{2}-[a-zA-Z]{2}-[\d]{2}$/;
                    var reg6  =/^[\d]{2}-[a-zA-Z]{2}-[a-zA-Z]{2}$/;
                    var reg7  =/^[\d]{2}-[a-zA-Z]{3}-[\d]{1}$/;
                    var reg8  =/^[\d]{1}-[a-zA-Z]{3}-[\d]{2}$/;
                    var reg9  =/^[a-zA-Z]{2}-[\d]{3}-[a-zA-Z]{1}$/;
                    var reg10 =/^[a-zA-Z]{1}-[\d]{3}-[-a-zA-Z]{2}$/;
                    var reg11 =/^[a-zA-Z]{3}-[\d]{2}-[a-zA-Z]{1}$/;
                    var reg12 =/^[a-zA-Z]{1}-[\d]{2}-[a-zA-Z]{3}$/;
                    var reg13 =/^[\d]{1}-[a-zA-Z]{2}-[\d]{3}$/;
                    var reg14 =/^[\d]{3}-[a-zA-Z]{2}-[\d]{1}$/;
                    
                    // Test only with dashes
                    if (!(reg1.test(card.LicensePlate) || 
                          reg2.test(card.LicensePlate) || 
                          reg3.test(card.LicensePlate) || 
                          reg4.test(card.LicensePlate) || 
                          reg5.test(card.LicensePlate) || 
                          reg6.test(card.LicensePlate) || 
                          reg7.test(card.LicensePlate) || 
                          reg8.test(card.LicensePlate) || 
                          reg9.test(card.LicensePlate) || 
                          reg10.test(card.LicensePlate) || 
                          reg11.test(card.LicensePlate) || 
                          reg12.test(card.LicensePlate) || 
                          reg13.test(card.LicensePlate) || 
                          reg14.test(card.LicensePlate)))
                    {
                        return errorResult(i, "invalidlicenseplate");
                    }
                }

                if (!hasGas) {
                    return errorResult(i);
                }

                if (invalidPin) {
                    return errorResult(i, "invalidpincode");
                }
            }
        }

        return successResult();
    }

    /**
     * @param {Array<typedefs.Card>} cards 
     * @returns {Promise<Array<Number>>}
     */
    submitCards(cards) {
        return new Promise((resolve, reject) => {
            console.log('post cards', cards);
            axios.post("card", cards).then((response) => {
                const result = JSON.parse(response.data);
                console.log('card response', result);
                if (result && result.Success) {
                    resolve(result.Ids);
                } else {
                    reject(result.Error);
                }
            })
                .catch((err) => {
                    reject();
                });
        });
    }
}

const cardService = new CardService();
export { cardService };