<template>
  <v-card>
    <v-toolbar dark color="primary" @click="detailsClick">
      <v-toolbar-title>
        <span>{{ title }}</span>
      </v-toolbar-title>
      <template v-if="card.Chauffeur">
        <v-divider vertical class="mx-4"></v-divider>
        <span :class="isMobile ? 'info-mobile' : ''">
          {{ card.Chauffeur }}</span
        >
      </template>
      <template v-if="card.LicensePlate">
        <v-divider vertical class="mx-4"></v-divider>
        <span :class="isMobile ? 'info-mobile' : ''">
          {{ card.LicensePlate }}</span
        >
      </template>
      <template v-if="card.CarDisplay">
        <v-divider vertical class="mx-4"></v-divider>
        <span :class="isMobile ? 'info-mobile' : ''">{{
          card.CarDisplay
        }}</span>
      </template>
      <template v-if="!card.LicensePlate && !card.CarDisplay">
        <div v-if="!defaultCard">
          <span
            :class="
              isMobile ? 'info-mobile info-click ml-8' : 'ml-8 info-click'
            "
            >{{ $t("cardconfig") }}</span
          >
        </div>
      </template>
      <v-spacer></v-spacer>
      <v-btn icon v-if="!defaultCard">
        <v-icon>{{
          showDetails ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <div v-show="showDetails">
        <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
        <v-card-text class="pb-0">
          <div v-if="!defaultCard">
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="6"
                class="py-0"
                v-if="settings.ChauffeurFieldEnabled"
              >
                <v-text-field
                  v-model="card.Chauffeur"
                  :label="$t('driver')"
                  maxlength="50"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6" class="py-0">
                <v-text-field
                  v-model="card.LicensePlate"
                  :label="$t('licenseplate')"
                  :class="{ required: settings.LicensePlateRequired }"
                  @blur="onLicensePlateBlur()"
                  :rules="settings.LicensePlateRequired ? requiredRules : []"
                  maxlength="20"
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
                md="6"
                class="py-0"
                v-if="settings.IncludeCardWishPin"
              >
                <v-text-field
                  type="password"
                  v-model="card.PinCode"
                  :label="$t('pin')"
                  :class="{ required: settings.PinCodeMandatory }"
                  :rules="settings.PinCodeMandatory ? requiredRules : []"
                  maxlength="10"
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
                md="6"
                class="py-0"
                 v-if="settings.CardRemarkFieldEnabled"
              >
                <v-text-field
                  v-model="card.Remark"
                  :label="$t('remark')"
                  maxlength="250"
                />
              </v-col>    
              <v-col
                cols="12"
                lg="3"
                md="6"
                class="py-0"
                 v-if="settings.CardReferenceFieldEnabled"
              >
                <v-text-field
                  v-model="card.CustomerReference"
                  :label="$t('cardreference')"
                  maxlength="100"
                />
              </v-col>                          
              <v-col
                cols="12"
                lg="3"
                md="6"
                class="py-0"
                v-if="settings.ShowCardOptionsPrepaid"
              >
                <v-checkbox
                  v-model="card.PrepaidPass"
                  :label="$t('prepaid')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div v-show="cardCount !== 1">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    class="mt-0"
                    v-model="customSettings"
                    v-on:change="customSettingsChanged()"
                    :label="
                      $t(hideLimit ? 'customsettingsprivate' : 'customsettings')
                    "
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-show="customSettings">
            <v-row v-show="!hideLimit">
              <v-col cols="12" lg="6" md="6" class="py-0">
                <v-select
                  v-model="card.LimitSet"
                  :items="limits"
                  item-text="Name"
                  item-value="Code"
                  class="required"
                  :label="$t('limitpercard')"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-divider v-show="!hideLimit"></v-divider>
            <v-row v-if="settings.ShowCardOptions" v-show="!hideEntryOf">
              <v-col cols="6" sm="3" class="py-0">
                <div class="mt-4 text-subtitle-1 no-break">
                  <span>{{ $t("entryof") }}</span>
                </div>
              </v-col>
              <v-col cols="6" sm="9" class="pt-4 pb-0">
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-if="settings.RecordChauffeurCode"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      v-model="card.RecordChauffeurCode"
                      :label="$t('drivercode')"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-if="settings.RecordKMAtStand"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      v-model="card.RecordKMAtStand"
                      :label="$t('mileage')"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-if="settings.RecordCarNumber"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      v-model="card.RecordCarNumber"
                      :label="$t('recordcarnumber')"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-if="settings.RecordCarReplacement"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      v-model="card.RecordCarReplacement"
                      :label="$t('replacementcar')"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-if="settings.RecordLicensePlate"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      v-model="card.RecordLicensePlate"
                      :label="$t('licenseplate')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" sm="3" class="py-0">
                <div class="mt-4 text-subtitle-1 no-break">
                  <span>{{ $t("fuel") }}</span>
                </div>
                <v-checkbox
                  v-if="settings.AllowMultipleProducts"
                  v-model="allGasProductsSelected"
                  color="primary"
                  class="no-break"
                  v-bind:label="$t('selectall')"
                  :indeterminate="gasProductsIndeterminate"
                  @click="productCategorySelectChanged('gas')"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="6" sm="9" class="pt-4 pb-0">
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-for="(product, i) in gasProducts"
                    :key="i"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      :value="product.Code"
                      :label="product.Name"
                      v-model="selectedProducts"
                      v-on:change="productChanged(product.Code)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="otherProducts && otherProducts.length"></v-divider>
            <v-row v-if="otherProducts && otherProducts.length">
              <v-col cols="6" sm="3" class="py-0">
                <div class="mt-4 text-subtitle-1 no-break">
                  <span>{{ $t("shop") }}</span>
                </div>
                <v-checkbox
                  v-if="settings.AllowMultipleProducts"
                  v-model="allOtherProductsSelected"
                  color="primary"
                  class="no-break"
                  v-bind:label="$t('selectall')"
                  :indeterminate="otherProductsIndeterminate"
                  @click="productCategorySelectChanged('other')"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="6" sm="9" class="pt-4 pb-0">
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                    md="6"
                    class="py-0"
                    v-for="(product, i) in otherProducts"
                    :key="i"
                  >
                    <v-checkbox
                      class="float-left ml-2 mt-0 no-break"
                      :label="product.Name"
                      :value="product.Code"
                      v-model="selectedProducts"
                      v-on:change="productChanged(product.Code)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
/*eslint no-debugger: 0*/

import * as typedefs from "../../../typedefs";
import StringUtils from "../../util/stringutils";
import { mapFields } from "vuex-map-fields";
import _ from "lodash";
import { carService } from "../../services/carservice";

export default {
  name: "card-data",
  data() {
    const gasProducts = _.filter(this.products, (item) => item.Type === 0);
    const otherProducts = _.filter(this.products, (item) => item.Type !== 0);
    const gasProductCodes = _.map(gasProducts, "Code");
    const otherProductCodes = _.map(otherProducts, "Code");
    return {
      /** @type {typedefs.Card} */
      card: {},
      customSettings: false,
      selectedProducts: [],
      gasProducts,
      otherProducts,
      gasProductCodes,
      otherProductCodes,
      allGasProductsSelected: false,
      allOtherProductsSelected: false,
      rules: {
        required: function (v) {
          return (v) => undefined !== v && v.length !== 0;
        },
      },
    };
  },
  props: {
    /** @type {typedefs.Card} */
    defaults: Object,
    /** @type {typedefs.Card} */
    initial: Object,
    defaultCard: Boolean,
    cardCount: Number,
    showDetails: Boolean,
    title: String,
    subtitle: String,
    /** @type {typedefs.Limit[]} */
    limits: Array,
    /** @type {typedefs.Product[]} */
    products: Array,
    requiredRules: [(v) => undefined !== v && v.length !== 0],
  },
  watch: {
    card: {
      handler: function (oldVal, newVal) {
        this.$emit("cardchanged", this.card);
      },
      deep: true,
    },
    defaults: {
      handler: function (oldVal, newVal) {
        if (this.defaults && !this.customSettings) {
          this.card = Object.assign(this.card, this.defaults);
          this.selectedProducts = this.card.Products
            ? this.card.Products.split(",")
            : [];
            
          this.recalculateAllSelections();
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    someGasProductsSelected() {
      return _.some(this.gasProductCodes, (item) =>
        _.includes(this.selectedProducts, item)
      );
    },
    someOtherProductsSelected() {
      return _.some(this.otherProductCodes, (item) =>
        _.includes(this.selectedProducts, item)
      );
    },
    gasProductsIndeterminate() {
      return (
        this.allGasProductsSelected &&
        !_.every(this.gasProductCodes, (g) =>
          _.includes(this.selectedProducts, g)
        )
      );
    },
    otherProductsIndeterminate() {
      return (
        this.allOtherProductsSelected &&
        !_.every(this.otherProductCodes, (g) =>
          _.includes(this.selectedProducts, g)
        )
      );
    },
    hideEntryOf() { 
      return !((this.companyType === "company") || (this.companyType !== "company" && this.settings.TerminalEntryOptionsPublicFlowVisible));      
    },
    hideLimit() {
      return this.companyType !== "company";
    },
    ...mapFields(["customCards", "settings", "companyType", "isMobile"]),
  },
  methods: {
    productCategorySelectChanged(productType) {
      let productCodes;
      let allSelected;

      if (productType === "gas") {
        productCodes = this.gasProductCodes;
        allSelected = this.allGasProductsSelected;
      } else {
        productCodes = this.otherProductCodes;
        allSelected = this.allOtherProductsSelected;
      }

      if (allSelected) {
        this.selectedProducts = _.uniq(
          _.concat(this.selectedProducts, productCodes)
        );
      } else {
        this.selectedProducts = _.difference(
          this.selectedProducts,
          productCodes
        );
      }

      this.card.Products = this.selectedProducts.join(",");
      this.$emit("cardchanged", this.card);
    },
    /** @param {typedefs.Product} product */
    productChanged(productCode) {
      let products = this.selectedProducts;

      if (!this.settings.AllowMultipleProducts) {
        if (_.includes(this.gasProductCodes, productCode)) {
          products = _.filter(
            this.selectedProducts,
            (item) =>
              !_.includes(this.gasProductCodes, item) || item === productCode
          );
        } else {
          products = _.filter(
            this.selectedProducts,
            (item) =>
              !_.includes(this.otherProductCodes, item) || item === productCode
          );
        }
      }

      this.selectedProducts = products;
      this.card.Products = products.join(",");
      this.recalculateAllSelections();

      this.$emit("cardchanged", this.card);
    },
    customSettingsChanged() {
      if (this.customSettings && this.card.PublicId) {
        this.customCards.push(this.card.PublicId);
      } else {
        this.customCards = this.customCards.filter(
          (c) => c !== this.card.PublicId
        );
      }
    },
    detailsClick() {
      if (!this.defaultCard) {
        this.$emit("detailschanged", this.card);
      }
    },
    recalculateAllSelections() {
      if (!this.allGasProductsSelected) {
        this.allGasProductsSelected = _.every(this.gasProductCodes, (g) =>
          _.includes(this.selectedProducts, g)
        );
      } else {
        if (
          _.every(
            this.gasProductCodes,
            (g) => !_.includes(this.selectedProducts, g)
          ) &&
          this.allGasProductsSelected
        ) {
          this.allGasProductsSelected = false;
        }
      }

      if (!this.allOtherProductsSelected) {
        this.allOtherProductsSelected = _.every(this.otherProductCodes, (g) =>
          _.includes(this.selectedProducts, g)
        );
      } else {
        if (
          _.every(
            this.otherProductCodes,
            (g) => !_.includes(this.selectedProducts, g)
          )
        ) {
          this.allOtherProductsSelected = false;
        }
      }
    },
    onLicensePlateBlur() {
      carService.getCarData(this.card.LicensePlate).then((data) => {
        this.card.CarDisplay = data.Model;
        this.$forceUpdate();
      });
    },
  },
  created() {
    //this.customSettings = this.defaultCard;
  },
  mounted() {
    this.card = Object.assign({}, this.defaults);
    this.card = Object.assign(this.card, this.initial);
    this.customSettings =
      this.defaultCard || this.customCards.indexOf(this.card.PublicId) !== -1;

    this.selectedProducts = this.card.Products
      ? this.card.Products.split(",")
      : [];
    this.$forceUpdate();
  },
};
</script>

<style scoped>
* {
  word-break: break-word;
}

.v-toolbar__title {
  min-width: 80px;
  white-space: pre-line !important;
}

.v-toolbar__content span {
  word-break: keep-all !important;
}

.info-mobile {
  font-size: 12px;
}

@media (max-width: 450px) {
  .info-mobile.info-click {
    display: none;
  }
}
</style>