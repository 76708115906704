<template>
  <v-card rounded>
    <v-card-title class="text-uppercase">
      <v-row>
        <v-col class="py-0">
          {{ $t("CustomerData") }}
        </v-col>
        <v-col class="text-right py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                light
                v-bind="attrs"
                v-on="on"
                icon
                @click="onRoute('contact')"
              >
                <v-icon color="primary" light>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("change") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <p class="primary--text" v-if="hasCompanyTitle">
            <span>{{ companyTitle }}</span>
          </p>
          <p v-bind:class="{ 'primary--text': !hasCompanyTitle }">
            <span>{{ getName() }}</span>
          </p>
          <p>
            <span>{{ street }}</span> &nbsp;
            <span>{{ housenr }}</span>
          </p>
          <p>
            <span>{{ postcode }}</span> &nbsp;
            <span>{{ city }}</span>
          </p>
          <p>
            <span v-if="country">{{ getCountry() }}</span>
          </p>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <p class="primary--text" v-if="kvkNumber">
            <span>{{ $t("CoCNumber") }}:</span> &nbsp;
            <span>{{ kvkNumber }}</span>
          </p>
          <p v-bind:class="{ 'primary--text': !kvkNumber }">
            <span>{{ $t("emailaddress") }}:</span> &nbsp;
            <span>{{ emailaddress }}</span>
          </p>
          <p v-if="website">
            <span> {{ $t("Website") }}: </span> &nbsp;
            <span>{{ website }}</span>
          </p>
          <div>
            <p class="primary--text">
              {{ $t("contactperson") }}
            </p>
            <p class="primary--text">
              <span>{{ getContact() }}</span> &nbsp;
              <span>{{ getBirthdate() }}</span>
            </p>
            <p>
              {{ contactEmailAddress }}
            </p>
            <p>
              {{ contactMobilePhone }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "contact-summary",
  computed: {
    hasCompanyTitle() {
      return this.companyTitle && this.companyTitle !== "" && this.companyType === "company"
    },
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
    ]),
  },
  data() {
    return {};
  },
  methods: {
    getName() {
      if (this.customerPrefix) {
        return `${this.customerFirstname} ${this.customerPrefix} ${this.customerLastname}`;
      }

      return `${this.customerFirstname} ${this.customerLastname}`;
    },
    getContact() {
      if (this.contactPrefix) {
        return `${this.contactFirstname} ${this.contactPrefix} ${this.contactLastname}`;
      }

      return `${this.contactFirstname} ${this.contactLastname}`;
    },
    getBirthdate() {
      if (this.contactBirthDate !== '')
      {
        return '('+this.contactBirthDate+')';
      }
    },
    getCountry() {
      /** @type {Object[]} */
      const countries = JSON.parse(this.$t("countrycodes"));
      return countries.find((c) => c.code === this.country)["name"];
    },
    onRoute(route) {
      this.$router.push(route);
    },
  },
  mounted() {},
};
</script>

<style scoped>
p {
  margin-bottom: 1px !important;
}

.edit {
  align-items: end;
  justify-content: flex-end;
}
</style>

