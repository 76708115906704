<template>
  <v-card rounded>
    <v-card-title class="text-uppercase">
      <v-row>
        <v-col class="py-0">
          {{ $t("Cards") }}
        </v-col>
        <v-col class="text-right py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                light
                v-bind="attrs"
                v-on="on"
                icon
                @click="onRoute('cards')"
              >
                <v-icon color="primary" light>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("change") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-0">
      <p class="primary--text">
        <span>{{ cards.length }}</span> &nbsp;
        <span>{{ $t(cards.length === 1 ? "Card" : "Cards") }}</span>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "card-summary",
  computed: {
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
      "invoiceByEmail",
    ]),
  },
  data() {
    return {};
  },
  methods: {
    onRoute(route) {
      this.$router.push(route);
    },
  },
  mounted() {},
};
</script>

<style scoped>
p {
  margin-bottom: 1px !important;
}

.edit {
  align-items: end;
  justify-content: flex-end;
}
</style>
