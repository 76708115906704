import axios from 'axios';
import * as typedefs from "../../typedefs";

/*eslint no-unused-vars: 0*/
/*eslint no-debugger: 0*/

class CarService {
    constructor() {

    }

    /**
     * @param {String} licensePlate
     * @returns {Promise<typedefs.CarData>}
     */
    getCarData(licensePlate) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'car',
                params: {
                    licensePlate: licensePlate
                }
            }).then((result) => {

                /** @type {Array<typedefs.Limit>} */
                const data = JSON.parse(result.data);

                console.log('car data', data);

                if (data && data.Success) {
                    resolve({ Model: data.Model });
                } else {
                    resolve({ Model: null });
                }
            })
                .catch(err => {
                    console.log('Error occurred while getting limits', err);
                    reject(err);
                });
        });
    }
}

const carService = new CarService();
export { carService };