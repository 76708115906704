<template>
  <v-container>
    <v-card rounded>
      <v-stepper v-model="routeStep" :vertical="!isMobile" v-if="!isMobile">
        <v-stepper-step
          step="1"
          class="link"
          :complete="isComplete(1)"
          @click="canRouteStep(1) ? onStepClick(1) : null"
        >
          {{ $t("ContactData") }}
        </v-stepper-step>
        <v-stepper-content step="1"></v-stepper-content>
        <v-stepper-step
          step="2"
          class="link"
          :complete="isComplete(2)"
          @click="canRouteStep(2) ? onStepClick(2) : null"
        >
          {{ $t("Cards") }}
        </v-stepper-step>
        <v-stepper-content step="2"></v-stepper-content>
        <v-stepper-step
          step="3"
          class="link"
          :complete="isComplete(3)"
          @click="canRouteStep(3) ? onStepClick(3) : null"
        >
          {{ $t("InvoiceData") }}
        </v-stepper-step>
        <v-stepper-content step="3"></v-stepper-content>
        <v-stepper-step step="4" :complete="isComplete(4)">
          {{ $t("complete") }}
        </v-stepper-step>
        <v-stepper-content class="pa-0" step="4"></v-stepper-content>
      </v-stepper>
      <v-stepper v-else v-model="routeStep">
        <v-stepper-header>
          <v-stepper-step
            step="1"
            class="link"
            :complete="isComplete(1)"
            @click="canRouteStep(1) ? onStepClick(1) : null"
          >
            {{ $t("ContactData") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            class="link"
            :complete="isComplete(2)"
            @click="canRouteStep(2) ? onStepClick(2) : null"
          >
            {{ $t("Cards") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            class="link"
            :complete="isComplete(3)"
            @click="canRouteStep(3) ? onStepClick(3) : null"
          >
            {{ $t("InvoiceData") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4" :complete="isComplete(4)">
            {{ $t("complete") }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-card>

    <v-card rounded v-if="!isMobile">
       <div v-html="advertHTML">
       </div>
    </v-card>
  </v-container>
</template>
<script>
/*eslint no-debugger: 0*/
/*eslint-disable no-undef*/

import { mapFields } from "vuex-map-fields";
import axios from "axios";

export default {
  name: "ProgressArea",
  data() {
    return {
      routeStep: 1,
      advertHTML: null,
      routes: {
        1: "/contact",
        2: "/cards",
        3: "/invoice",
      },
    };
  },
  watch: {
    $route: "currentRoute",
  },
  computed: {
    ...mapFields(["isMobile"]),
  },
  methods: {
    isComplete(step) {
      return step <= window.maxRouteStep;
    },
    canRouteStep(step) {
      return step <= window.maxRouteStep;
    },
    currentRoute() {
      this.routeStep = parseInt(this.$route.meta.step);

      if (window.maxRouteStep === undefined || window.maxRouteStep === null) {
        window.maxRouteStep = this.routeStep;
      }

      if (this.routeStep >= window.maxRouteStep) {
        window.maxRouteStep = this.routeStep;
      }

      if (this.routeStep === 1 && window.maxRouteStep === 4) {
        window.maxRouteStep = this.routeStep;
      }

      if (this.routeStep === 3 && window.maxRouteStep === 4) {
        window.maxRouteStep = this.routeStep;
      }
    },
    onStepClick(step) {
      if (step !== this.routeStep) {
        this.$router.push(this.routes[step]);
      }
    },
  },
  mounted() {
    this.currentRoute();
    var instance = axios.create({ baseURL: '/' });
    instance.get("advert.html").then(response => (this.advertHTML = response.data));
  },
};
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>