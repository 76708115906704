<template>
  <v-app>
    <v-app-bar
      app
      color="teal lighten-3"
      prominent
      shrink-on-scroll
      :src="headerUrl"
    >
      <v-img
        class="mx-2"
        :src="logoUrl"
        v-if="!isMobile"
        max-height="150"
        max-width="150"
        contain
      ></v-img>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" md="3">
            <ProgressArea></ProgressArea>
          </v-col>
          <v-col cols="12" md="9">
            <router-view v-if="settingsLoaded"></router-view>
            <v-container v-else>
              <v-row>
                <v-col class="py-0">
                  <v-skeleton-loader
                    class="mb-6"
                    elevation="2"
                    type="list-item-three-line, article, article, article, actions"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      :app="!isMobile"
      :fixed="!isMobile"
      color="primary"
      padless
      :class="isMobile ? 'mobile-footer' : ''"
    >
      <v-row justify="center" no-gutters>
        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          :href="settings.URLTermsAndConditions"
          target="_blank"
        >
          {{ $t("termsandconditions") }}
        </v-btn>
        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          :href="settings.URLPrivacyPolicy"
          target="_blank"
        >
          {{ $t("privacypolicy") }}
        </v-btn>
        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          :href="settings.URLToCorporateWebSite"
          target="_blank"
        >
          {{ new Date().getFullYear() }} —
          <strong>{{ settings.CompanyName }}</strong>
        </v-btn>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
/*eslint-disable no-undef*/

import ProgressArea from "./components/ProgressArea.vue";
import { mapFields } from "vuex-map-fields";
import { settingsService } from "./services/settingsservice";
import { analyticService } from "./services/analyticsservice";

const headerUrl = config.HEADER_IMAGE_URL;
const logoUrl = config.LOGO_IMAGE_URL;
const mobileBreakpoint = config.MOBILE_BREAKPOINT || 630;

//import ContactArea from './components/contact/ContactArea.vue'
//import CheckArea from './components/check/CheckArea.vue'

function getImageUrl(imageName) {
  if (imageName) {
    return `/images/${imageName}`;
  }
  return "";
}

export default {
  name: "App",

  components: {
    ProgressArea,
    //,ContactArea
    //,CheckArea
  },

  computed: {
    ...mapFields(["settings", "isMobile"]),
  },

  data: () => ({
    settingsLoaded: false,
    logoUrl: getImageUrl(logoUrl),
    headerUrl: getImageUrl(headerUrl),
  }),

  watch: {
    $route(to, from) {
      document.getElementsByTagName("html")[0].scrollTop = 0;
      analyticService.pageView(this, this.$gtag, to.path);
    },
  },

  created() {
    this.$gtag.config({
      user_id: analyticService.getUserId(this),
    });

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    settingsService.getSettingss().then((data) => {
      this.settings = data;
      this.settingsLoaded = true;
    });

    analyticService.pageView(this, this.$gtag, this.$route.path);
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  methods: {
    onResize() {
      var newIsMobile = window.innerWidth <= mobileBreakpoint;
      if (newIsMobile != this.isMobile) {
        console.log("change mobile", newIsMobile);
        this.isMobile = newIsMobile;
      }
    },
  },
};
</script>

<style>
.v-input__icon--append > .v-icon:not(.error--text) {
  display: none;
}

.required label::after {
  content: "*";
  color: #ff0000;
}

.v-file-input__text {
  cursor: pointer;
}

.no-break,
.no-break label,
.no-break span {
  word-break: keep-all !important;
  overflow-wrap: normal !important;
}

.mobile-footer {
}

.mobile-footer .v-btn {
  font-size: 12px !important;
  height: 20px !important;
}
</style>