import axios from 'axios';
import * as typedefs from "../../typedefs";

/*eslint no-unused-vars: 0*/

class ProductService {

    constructor() {

    }

    /**
     * @param {String} companyType
     * @returns {Promise<typedefs.Product[]>}
     */
    getProducts(companyType) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'product',
                params: {
                    companyType: companyType
                }
            }).then((result) => {
                const data = JSON.parse(result.data);
                console.log('product data', data);
                resolve(data);
            })
                .catch(err => {
                    console.log('Error occurred while getting products', err);
                    reject(err);
                });
        });
    }
}

const productService = new ProductService();
export { productService };