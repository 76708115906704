import axios from 'axios';
import * as typedefs from "../../typedefs";

/*eslint no-unused-vars: 0*/

class MandateService {

    constructor() {

    }

    /**
     * @returns {Promise<typedefs.Bank[]>}
     */
    getBanks() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'bank',
                params: {
                }
            }).then((result) => {
                const data = JSON.parse(result.data);
                console.log('bank data', data);
                resolve(data);
            })
                .catch(err => {
                    console.log('Error occurred while getting banks', err);
                    resolve([]);
                });
        });
    }

    /**     
     * @param {Number} requestid 
     * @param {String} companyType 
     * @param {String} iban 
     * @param {String} bic 
     */
    downloadMandate(requestid, companyType, iban, bic) {
        const url = `${axios.defaults.baseURL}/mandate?requestId=${requestid}&companyType=${companyType}&iban=${iban}&bic=${bic}`;
        document.location.href = url;
    }
    
    /**
     * 
     * @param {String} mandatetype
     * @param {Number} requestid 
     * @param {String} iban
     * @returns {Promise}
     */
    registerCheckboxMandate(mandatetype, requestid, iban) {
        console.log("starting registerCheckboxMandate");
        return new Promise((resolve, reject) => {
            if (mandatetype !== 'checkbox') {
                resolve();
            } else {
                axios({
                    method: 'post',
                    url: 'mandate',
                    params: {
                        requestId: requestid,
                        iban: iban
                    }
                })
                .then((response) => {
                    const data = JSON.parse(response.data);
                    if (!data.Success) {
                        reject(data.Error);
                    } else {
                        resolve();
                    }
                })
                .catch((err) => {
                    reject(err);
                })
            }
        });
    }

    /**
     * 
     * @param {Number} requestid 
     * @param {FileList} file 
     * @returns {Promise}
     */
    uploadMandate(requestid, file) {
        return new Promise((resolve, reject) => {
            if (!file || !file.length) {
                resolve();
            } else {
                let formData = new FormData();
                formData.append("files[0]", file[0]);

                axios({
                    method: 'post',
                    url: 'mandate',
                    params: {
                        requestId: requestid
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                })
                    .then((response) => {
                        const data = JSON.parse(response.data);
                        if (!data.Success) {
                            reject(data.Error);
                        } else {
                            resolve();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }

    /**
     * 
     * @param {typedefs.InvoiceData} data 
     * @returns {Promise}
     */
    updateInvoiceData(data) {
        return new Promise((resolve, reject) => {
            console.log('post invoice data', data);
            axios.post("invoice", data).then((response) => {
                const result = JSON.parse(response.data);
                console.log('invoice response', result);
                if (result && result.Success) {
                    resolve();
                } else {
                    reject();
                }
            })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**     
     * @param {typedefs.InvoiceData} data 
     * @returns {Promise<String>}
     */
    issueEmandate(data) {
        return new Promise((resolve, reject) => {
            if (data.MandateType !== "emandate") {
                resolve('/#/success');
            } else {
                console.log('post emandate data', data);
                axios.post("emandate", data).then((response) => {
                    const result = JSON.parse(response.data);
                    console.log('emandate response', result);
                    if (result && result.Success) {
                        resolve(result.Url);
                    } else {
                        reject();
                    }
                })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    }

    checkEmandate(entranceCode) {
        return axios.post(`emandate/${entranceCode}/check`);
    }
}

const mandateService = new MandateService();
export { mandateService as mandateService };