<template>
  <v-container id="contact" fluid tag="section">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <contact-summary></contact-summary>
        </v-col>
        <v-col cols="12" md="3">
          <card-summary></card-summary>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card rounded>
            <v-card-title class="text-uppercase pb-0">{{
              $t("billinginformation")
            }}</v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" sm="8" class="py-0">
                  <v-text-field
                    v-model="invoiceEmail"
                    :label="$t('emailaddress')"
                    :rules="
                      (settings.InvoiceEmailAddressReadonly && settings.InvoiceEmailAddressVisible)
                        ? emailRules.concat(requiredRules)
                        : emailRules
                    "
                    maxlength="250"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-checkbox
                    class="float-left"
                    :label="$t('invoicebyemail')"
                    :disabled="settings.InvoiceEmailAddressReadonly"
                    v-if="settings.InvoiceEmailAddressVisible"
                    v-model="invoiceByEmail"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="settings.MandateEnabled">
        <v-col cols="12">
          <v-card rounded>
            <v-card-title class="text-uppercase">
              <v-row>
                <v-col cols="12" md="6" class="pt-0">
                  {{ $t("mandateinfo") }}
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <span class="text-subtitle-2 primary--text debitinfo">
                    {{ $t("debitinfo") }}
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-row>
                    <v-col cols="12" sm="2" class="pt-5 required">
                      <label>{{ $t("mandatemethod") }}</label>
                    </v-col>
                    <v-col cols="12" sm="10" class="pa-0 ma-0">
                      <v-radio-group v-model="mandateFileType">
                        <v-col
                          class="pa-0 ma-0"
                          v-if="settings.EmandateEnabled"
                        >
                          <v-radio
                            :label="$t('emandate')"
                            value="emandate"
                          ></v-radio>
                        </v-col>
                        <v-col
                          class="pa-0 ma-0"
                          v-if="settings.ManualMandateEnabled"
                        >
                          <v-radio value="manual" :label="$t('manualmandate')">
                          </v-radio>
                        </v-col>
                        <v-col
                          class="pa-0 ma-0"
                          v-if="settings.MandateByCheckboxEnabled && companyType !== 'company'"
                        >
                          <v-radio value="checkbox" :label="$t('manualmandatebycheckbox')">
                          </v-radio>
                        </v-col>                        
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-row v-if="mandateFileType !== 'checkbox'">
                    <v-col cols="12" sm="4" class="pt-5">
                      <label>{{ $t("mandatetype") }}</label>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0 ma-0">
                      <v-radio-group v-model="mandateType" row>
                        <v-col cols="12" md="6" class="pa-0 ma-0">
                          <v-radio
                            :label="$t('Bussiness')"
                            value="company"
                          ></v-radio>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-0 ma-0">
                          <v-radio
                            :label="$t('Private')"
                            value="private"
                          ></v-radio>
                        </v-col>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        class="pt-0 required"
                        v-model="mandateIban"
                        v-if="mandateFileType === 'manual' || mandateFileType === 'checkbox'"
                        :label="$t('iban')"
                        :rules="requiredRules.concat(ibanRules)"
                        append-icon="mdi-alert-circle"
                        maxlength="50"
                      />
                      <v-text-field
                        v-model="mandateMaxAmount"
                        v-if="
                          mandateFileType === 'emandate' &&
                          mandateType === 'company'
                        "
                        :label="$t('maxamount')"
                        :rules="maxAmountRules"
                        append-icon="mdi-alert-circle"
                        :hint="settings.EmandateMinAmount + ' - 99999'"
                        :persistent-hint="true"
                        type="number"
                        :min="settings.EmandateMinAmount"
                        max="99999"
                        class="required"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="mandateFileType === 'checkbox' && companyType !== 'company' ">
                      <v-checkbox
                        v-model="mandatecheckagreed"
                        label="Ik ga akkoord met de maandelijkse afschrijving van de pas- en transactiekosten.">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-row >
                    <v-col cols="12" class="pb-0">
                      <v-select
                        class="pa-0 ma-0 required"
                        v-model="mandateBank"
                        v-if="mandateFileType === 'emandate'"
                        :rules="requiredRules"
                        append-icon="mdi-alert-circle"
                        :items="banks"
                        item-text="Name"
                        item-value="Id"
                        :label="$t('bank')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        class="pa-0 ma-0 required"
                        v-model="mandateBic"
                        v-if="mandateFileType === 'manual'"
                        :rules="requiredRules.concat(bicRules)"
                        append-icon="mdi-alert-circle"
                        :label="$t('bic')"
                        maxlength="50"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-show="mandateFileType === 'manual'">
                <v-col cols="12">
                  <p class="download" @click="onMandateClick">
                    <span>
                      <span class="primary--text">
                        <v-icon>mdi-printer</v-icon>
                      </span>
                      <span class="primary--text">
                        {{
                          $t(
                            settings.ManualMandateFileUploadEnabled
                              ? "manualmandateinfo"
                              : "manualmandateinfonoupload"
                          )
                        }}
                      </span>
                    </span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    :label="$t('file')"
                    accept="application/pdf,image/jpg,image/jpeg,image/png"
                    append-icon="$file"
                    prepend-icon=""
                    :placeholder="
                      $t(
                        !documentDownloaded
                          ? 'filenotuploaded'
                          : 'clickforfileupload'
                      )
                    "
                    :disabled="
                      !documentDownloaded || mandateFileType === 'emandate'
                    "
                    name="file"
                    id="file"
                    ref="file"
                    v-show="settings.ManualMandateFileUploadEnabled"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="settings.TermsAndConditionsCheckboxEnabled">
        <v-col cols="12" class="text-left">
          <v-card rounded>
            <v-card-text>
              <v-checkbox
                v-model="termsagreed">
                <template v-slot:label>
                  <div>
                    {{ $t('accepttermsandconditions1') }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          :href="settings.URLTermsAndConditions"
                          @click.stop
                          v-on="on"
                        >
                          {{ $t('accepttermsandconditions2')}}
                        </a>
                      </template>
                      {{ $t('opensinnewwindow') }}
                    </v-tooltip>
                  </div>
                </template>                
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-left">
          <v-btn color="primary" @click="onPrevious()">
            {{ $t("previous") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            color="primary"
            class="mr-0"
            :loading="nextloading"
            @click="onNext"
          >
            {{ $t("Next") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar bottom multi-line timeout="10000" v-model="errsnackbar">
        {{ errsnackbar_text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errsnackbar = false">
            {{ $t("Close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>

<script>
/*eslint no-debugger: 0*/

import * as typedefs from "../../../typedefs";
import { mapFields } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";
import ContactSummary from "../contact/ContactSummary.vue";
import CardSummary from "../cards/CardSummary.vue";
import { mandateService } from "../../services/mandateservice";
import { analyticService } from "../../services/analyticsservice";
import * as iban from "iban";
import * as bic from "bic";

export default {
  computed: {
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
      "invoiceByEmail",
      "mandateType",
      "mandateBank",
      "mandateIban",
      "mandateBic",
      "mandateFileType",
      "mandateMaxAmount",
      "awaitingMandate",
      "settings",
      "preferredLocation",
      "mandateError",
      "estimateAmount",
    ]),
  },
  components: {
    ContactSummary,
    CardSummary,
  },
  data() {
    return {
      valid: true,
      /** @type {Array<typedefs.Bank>} */
      banks: [],
      documentDownloaded: false,
      requiredRules: [
        (v) => undefined !== v && v !== null && v && v.length !== 0,
      ],
      emailRules: [
        (v) =>
          !v || v === "" || /.+@.+\..+/.test(v) || this.$i18n.t("EmailInvalid"),
      ],
      ibanRules: [
        (v) => !v || v === "" || iban.isValid(v) || this.$i18n.t("ibaninvalid"),
      ],
      bicRules: [
        (v) => !v || v === "" || bic.isValid(v) || this.$i18n.t("bicinvalid"),
      ],      
      maxAmountRules: [
        (v) => undefined !== v && v !== null,
        (v) =>
          (v >= this.settings.EmandateMinAmount && v <= 99999) ||
          "" + this.settings.EmandateMinAmount + " - 99999",
      ],
      errsnackbar: false,
      errsnackbar_text: "",
      nextloading: false,
      loading: false,
      mandatecheckagreed: false,
      termsagreed: false,
    };
  },
  watch: {
    settings() {
      if (!this.mandateFileType || this.mandateFileType === "") {
        if (
          this.settings.MandateEnabled &&
          this.settings.ManualMandateEnabled &&
          !this.settings.EmandateEnabled
        ) {
          this.mandateFileType = "manual";
        } else if (
          this.settings.MandateEnabled &&
          !this.settings.ManualMandateEnabled &&
          this.settings.EmandateEnabled
        ) {
          this.mandateFileType = "emandate";
        }
      }

      if (!this.mandateMaxAmount && this.settings.EmandateMinAmount) {
        this.mandateMaxAmount = this.settings.EmandateMinAmount;
      }
    },
  },
  methods: {
    onNext() {
      if (this.$refs.form.validate()) {
        this.onSubmit();
      } else {
        this.errsnackbar = true;
        this.errsnackbar_text = this.getValidationError();
      }
    },
    onPrevious() {
      this.$router.push("cards");
    },
    onMandateClick() {
      if (this.$refs.form.validate()) {
        this.documentDownloaded = true;

        mandateService.downloadMandate(
          this.requestid,
          this.mandateType,
          this.mandateIban,
          this.mandateBic
        );
      } else {
        this.errsnackbar = true;
        this.errsnackbar_text = this.getValidationError();
      }
    },
    onSubmit() {
      const file = document.getElementById("file");
      const files = file ? file.files : [];

      this.$store.dispatch("persist");

      if (
        !files ||
        (files.length === 0 &&
          this.mandateFileType === "manual" &&
          this.settings.ManualMandateFileUploadEnabled)
      ) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("uploadsigneddocument");
      } else if (this.mandateFileType === "checkbox" &&
                 this.mandatecheckagreed !== true) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("agreemandatecheckbox");
      } else if (
        this.settings.MandateEnabled &&
        (!this.mandateFileType || this.mandateFileType === "")
      ) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.getValidationError();
      } else if (
        this.invoiceByEmail &&
        (!this.invoiceEmail || this.invoiceEmail === "")
      ) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("invoiceemailaddress");
      } else if (
        !this.settings.ManualMandateFileUploadEnabled &&
        this.mandateFileType === "manual" &&
        !this.documentDownloaded
      ) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("filenotuploaded");
      } else if (
        this.termsagreed !== true &&
        this.settings.TermsAndConditionsCheckboxEnabled
      ) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("termsnotagreed");
      } else {
        this.nextloading = true;

        const data = {
          Id: this.requestid,
          InvoiceEmailAddress: this.invoiceEmail,
          IBAN: this.mandateIban,
          BankCode: this.mandateBank,
          MandateType: this.mandateFileType,
          CompanyType: this.mandateType,
          MaxAmount: this.mandateMaxAmount,
          PreferredLocationCode: this.preferredLocation,
          invoiceByEmail: this.invoiceByEmail,
          EstimateAmount: this.estimateAmount,
        };

        mandateService
          .registerCheckboxMandate(this.mandateFileType, this.requestid, this.mandateIban)
          .then(() => {
            mandateService
            .uploadMandate(this.requestid, files)
            .then(() => {
              mandateService
                .updateInvoiceData(data)
                .then(() => {
                  mandateService
                    .issueEmandate(data)
                    .then((url) => {
                      analyticService
                        .purchase(
                          this,
                          this.$gtag,
                          this.requestid.toString(),
                          this.cards.length
                        )
                        .then(() => {
                          if (url) {
                            this.awaitingMandate = true;
                            this.$store.dispatch("persist");

                            document.location.href = url;
                          }
                        });
                    })
                    .catch((err) => {
                      console.log("emandate request data failed.", err);

                      this.errsnackbar = true;
                      this.errsnackbar_text = err || this.$t("generalerror");
                    })
                    .finally(() => {
                      this.nextloading = false;
                    });
                })
                .catch((err) => {
                  console.log("update invoice data failed.", err);

                  this.errsnackbar = true;
                  this.errsnackbar_text = err || this.$t("generalerror");
                })
                .finally(() => {
                  this.nextloading = false;
                });
            })
            .catch((err) => {
              console.log("uploading file failed.", err);

              this.errsnackbar = true;
              this.errsnackbar_text = err || this.$t("generalerror");
              this.nextloading = false;
            })
          })
          .catch((err) => {
            console.log("uploading file failed.", err);

            this.errsnackbar = true;
            this.errsnackbar_text = err || this.$t("generalerror");
            this.nextloading = false;
          })
      }
    },
    getValidationError() {
      if (!this.mandateFileType || this.mandateFileType === "") {
        return this.$t("selectmandatemethod");
      } else if (
        this.mandateFileType === "emandate" &&
        (!this.mandateBank || this.mandateBank === "")
      ) {
        return this.$t("selectmandatebank");
      }

      return this.$t("validationallfields");
    },
  },
  mounted() {
    // If there is no request id then the page was refreshed and the data is lost
    // Go back to contacts page
    if (!this.requestid) {
      this.$store.dispatch("rebuild");
      this.$forceUpdate();

      if (!this.requestid) {
        this.$router.replace("contact");
        return;
      }
    }

    if (!this.mandateType || this.mandateType === "") {
      this.mandateType = this.companyType;
    }

    if (!this.mandateMaxAmount && this.settings.EmandateDefaultMaxAmount) {
      this.mandateMaxAmount = this.settings.EmandateDefaultMaxAmount;
    }

    mandateService.getBanks().then((data) => {
      this.banks = data;
    });

    if (!this.invoiceEmail || this.invoiceEmail === "") {
      this.invoiceEmail = this.emailaddress;
    }

    this.invoiceByEmail = this.settings.InvoiceEmailAddressDefaultValue;

    if (this.mandateError) {
      this.mandateError = false;
      this.errsnackbar = true;
      this.errsnackbar_text = this.$t("mandateerror");

      this.$store.dispatch("persist");
    }
  },
};
</script>

<style scoped>
.debitinfo {
  text-align: right;
  float: right;
  text-transform: initial;
  padding-top: 5px;
}

.download {
  cursor: pointer;
}

* {
  word-break: break-word;
}
</style>

