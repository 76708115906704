<template>
  <v-container id="cards" tag="section" class="pa-0">
    <v-row>
      <v-col cols="12" md="4" v-if="settings.ShowEstimateAmount">
        <v-card>
          <v-card-title class="text-uppercase pb-0">{{
            $t("estimatevolumepermonth")
          }}</v-card-title>
          <v-card-text class="py-0">
           <v-text-field
                  v-model="estimateAmount"
                  :label="$t('volume')"
                  maxlength="7"
                  :rules="requiredRules.concat(numberRules)"
                />
          </v-card-text>
        </v-card>
      </v-col>      
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="text-uppercase pb-0">{{
            $t("Cards")
          }}</v-card-title>
          <v-card-text class="py-0">
            <card-selector
              :maxcards="maxcards"
              :mincards="mincards"
              :initial="numberOfCards"
              :companytype="companyType"
              v-on:numberchanged="cardNumberChanged"
            ></card-selector>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="settings.ShowEstimateAmount? 5: 9"
        v-if="settings.PreferredTankLocationEnabled">
        <v-card>
          <v-card-title class="text-uppercase pb-0 no-break">{{
            $t("preferredlocation")
          }}</v-card-title>
          <v-card-text class="py-0">
            <v-autocomplete
              v-model="preferredLocation"
              :placeholder="$t('locationsearch')"
              :items="locations"
              clearable
              item-text="Name"
              item-value="LocationCode"
              :no-data-text="$t('nolocationmatch')"
            >
            </v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <template v-if="contactThresholdReached">
        <v-card rounded>
          <v-card-title class="text-uppercase">{{
            contactThresholdMaximum
              ? cardMaxamountNoshorthandTitle
              : cardMaxamountTitle
          }}</v-card-title>
          <v-card-text>
            <p>
              {{
                contactThresholdMaximum
                  ? cardMaxamountNoshorthandText
                  : cardMaxamountText
              }}
            </p>
            <div v-if="!contactThresholdMaximum">
              <v-radio-group v-model="shorthandRequestAnswer">
                <v-radio :label="cardMaxamountContinueText" :value="true">
                </v-radio>
                <v-radio :label="cardMaxamountIgnoreText" :value="false">
                </v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="ml-2 mb-3"
              @click="onShorthandAnswer()"
            >
              {{ $t("continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            <card-data
              :defaultCard="true"
              :showDetails="true"
              :cardCount="cards.length"
              :limits="limits"
              :products="products"
              :title="
                $t(hideLimit ? 'preferredsettingsprivate' : 'preferredsettings')
              "
              :subtitle=" this.companyType === 'company' ? cardSettingsCompanyText : cardSettingsPrivateText"
              :defaults="defaultcard"
              v-on:cardchanged="onDefaultCardChanged"
            ></card-data>
          </v-col>
        </v-row>
        <v-row v-for="(card, i) in cards" :key="i">
          <v-col cols="12">
            <card-data
              :defaultCard="false"
              :showDetails="details[i]"
              :cardCount="cards.length"
              :limits="limits"
              :products="products"
              :title="$t('Card') + ' ' + (i + 1)"
              :defaults="defaultcard"
              :initial="card"
              v-on:cardchanged="onCardChanged"
              v-on:detailschanged="onCardDetailsChanged"
            ></card-data>
          </v-col>
        </v-row>
      </template>
    </template>
    <v-row v-if="!contactThresholdReached">
      <v-col cols="6" class="text-left">
        <v-btn color="primary" @click="onPrevious()">
          {{ $t("previous") }}
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          color="primary"
          class="mr-0"
          :loading="cardLoading"
          @click="onNext"
        >
          {{ $t("Next") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar top v-model="errsnackbar" :timeout="errsnackbar_timeout">
      {{ errsnackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errsnackbar = false">
          {{ $t("Close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
/*eslint no-debugger: 0*/
/*eslint no-self-assign: 0*/

import { mapFields } from "vuex-map-fields";
import CardSelector from "./CardSelector.vue";
import CardData from "./CardData.vue";
import { v4 as uuidv4 } from "uuid";
import { limitService } from "../../services/limitservice";
import { mandateService } from "../../services/mandateservice";
import { productService } from "../../services/productservice";
import { cardService } from "../../services/cardservice";
import { locationService } from "../../services/locationservice";
import * as typedefs from "../../../typedefs";
import StringUtils from "../../util/stringutils";

/*eslint-disable no-undef*/
const cardSettingsPrivateText = config.CARD_SETTINGS_PRIVATE_TEXT;
const cardSettingsCompanyText = config.CARD_SETTINGS_COMPANY_TEXT;
const cardMaxamountText = config.CARD_MAXAMOUNT_TEXT;
const cardMaxamountTitle = config.CARD_MAXAMOUNT_TITLE;
const cardMaxamountContinueText = config.CARD_MAXAMOUNT_CONTINUE_TEXT;
const cardMaxamountIgnoreText = config.CARD_MAXAMOUNT_IGNORE_TEXT;
const cardMaxamountNoshorthandTitle = config.CARD_MAXAMOUNT_NOSHORTHAND_TITLE;
const cardMaxamountNoshorthandText = config.CARD_MAXAMOUNT_NOSHORTHAND_TEXT;

export default {
  components: { CardSelector, CardData },
  computed: {
    contactThresholdReached() {
      return (
        (this.contactThreshold > 0 &&
        this.numberOfCards >= this.contactThreshold &&
          !this.shorthandRequestIgnored) ||
        this.contactThresholdMaximum ||
        this.estimateAmount >= this.settings.EstimateAmountContactThreshold
      );
    },
    contactThresholdMaximum() {
      return this.contactThreshold > 0 && this.numberOfCards > this.maxcards;
    },
    hideLimit() {
      return this.companyType !== "company";
    },
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
      "invoiceByEmail",
      "awaitingMandate",
      "settings",
      "preferredLocation",
      "shorthandRequest",
      "estimateAmount",
    ]),
  },
  data() {
    return {
      errsnackbar: false,
      errsnackbar_timeout: 4000,
      errsnackbar_text: "",
      numberOfCards: 1,
      mincards: 1,
      maxcards: 1,
      contactThreshold: 0,
      loading: false,
      cardLoading: false,
      /** @type {typedefs.Limit[]} */
      limits: [],
      /** @type {typedefs.Product[]} */
      products: [],
      /** @type {Array<Boolean>} */
      details: [],
      /** @type {typedefs.Location[]} */
      locations: [],
      cardSettingsPrivateText: cardSettingsPrivateText[this.$i18n.locale],
      cardSettingsCompanyText: cardSettingsCompanyText[this.$i18n.locale],
      cardMaxamountText: cardMaxamountText[this.$i18n.locale],
      cardMaxamountTitle: cardMaxamountTitle[this.$i18n.locale],
      cardMaxamountNoshorthandText:
        cardMaxamountNoshorthandText[this.$i18n.locale],
      cardMaxamountNoshorthandTitle:
        cardMaxamountNoshorthandTitle[this.$i18n.locale],
      cardMaxamountContinueText: cardMaxamountContinueText[this.$i18n.locale],
      cardMaxamountIgnoreText: cardMaxamountIgnoreText[this.$i18n.locale],
      shorthandRequestAnswer: true,
      shorthandRequestIgnored: false,
      requiredRules: [
        (v) => undefined !== v && v !== null && v && v.length !== 0,
      ],      
      numberRules: [
        (v) =>
          /^[0-9]+$/.test(v) ||
          this.$i18n.t("InvalidEstimateAmount"),
      ],
    };
  },
  methods: {
    getNewCard() {
      console.log("new card ", this.requestid);
      return {
        Id: uuidv4(),
        PublicId: uuidv4(),
        NewCustomerRequestId: this.requestid,
        IsBusinessRequest: this.companyType === "company",
        Limit: this.defaultLimit,
      };
    },
    validateCards() {
      const validationResult = cardService.validateCards(
        this.cards,
        this.limits,
        this.products,
        this.settings
      );

      if (!validationResult.valid) {
        this.handleCardDetailsChanged(this.cards[validationResult.index]);
        this.errsnackbar = true;
        this.errsnackbar_text = validationResult.error
          ? this.$t(validationResult.error)
          : StringUtils.cformat(
              this.$t(
                this.hideLimit ? "validationcardprivate" : "validationcard"
              ),
              validationResult.index + 1
            );
      }

      return validationResult.valid;
    },
    onShorthandAnswer() {
      if (this.shorthandRequestAnswer || this.contactThresholdMaximum) {
        this.onFinishRequest();
      } else {
        this.onIgnoreFinishRequest();
      }
    },
    onIgnoreFinishRequest() {
      this.shorthandRequestIgnored = true;
    },
    onFinishRequest() {
      /** @type {typedefs.InvoiceData} */
      const data = {
        Id: this.requestid,
        ContactRequested: true,
        ContactRequestCardNumber: this.numberOfCards,
        PreferredLocationCode: this.preferredLocation,
        EstimateAmount: this.estimateAmount,
      };
      mandateService
        .updateInvoiceData(data)
        .then(() => {
          this.shorthandRequest = true;

          this.$store.dispatch("persist");

          const path =
            this.shorthandRequest && this.contactThresholdMaximum
              ? "successcontactmax"
              : this.shorthandRequest
              ? "successcontact"
              : "success";

          this.$router.push(path);
        })
        .catch((err) => {
          console.log("update invoice data failed.", err);

          this.errsnackbar = true;
          this.errsnackbar_text = err || this.$t("generalerror");
        });
    },
    onPrevious() {
      this.$router.push("contact");
    },
    onNext() {
      const valid = this.validateCards();
      if (valid) {
        this.cardLoading = true;
        cardService
          .submitCards(this.cards)
          .then((ids) => {
            console.log("returned ids", ids);
            for (let i = 0; i < ids.length; i++) {
              this.cards[i].Id = ids[i];
            }

            this.cards = this.cards;
            this.$store.dispatch("persist");
            this.$router.push("invoice");
          })
          .catch((err) => {
            this.errsnackbar = true;
            this.errsnackbar_text = err
              ? this.$t(err)
              : this.$t("generalerror");
          })
          .finally(() => {
            this.cardLoading = false;
          });
      }
    },
    cardNumberChanged(newNumber) {
      this.numberOfCards = newNumber;
      this.handleCardNumberChange();
    },
    onDefaultCardChanged(card) {
      this.handleDefaultCardChange(card);
    },
    onCardChanged(card) {
      this.handleCardChange(card);
    },
    onCardDetailsChanged(card) {
      this.handleCardDetailsChanged(card);
    },
    handleCardDetailsChanged(card) {
      if (this.numberOfCards === 1) {
        this.details[0] = true;
      } else {
        let index = this.cards.findIndex((c) => c.Id == card.Id);

        for (let i = 0; i < this.cards.length; i++) {
          if (i == index) {
            // Flip state of the subject panel
            this.details[i] = true;
          } else {
            // Close everything else
            this.details[i] = false;
          }
        }
      }

      this.$forceUpdate();
    },
    /** @param {typedefs.Card} card */
    handleCardChange(card) {
      let index = this.cards.findIndex((c) => c.Id == card.Id);
      if (index !== -1) {
        this.cards[index] = card;
        this.$forceUpdate();
      }
    },
    handleDefaultCardChange(card) {
      if (card) {
        this.defaultcard = Object.assign({}, card);
      }
    },
    handleCardNumberChange() {
      // First default opening
      if ((!this.cards || !this.cards.length) && this.numberOfCards === 1) {
        this.cards = [this.getNewCard()];
        this.details = [true];
      } else if (this.numberOfCards === 0) {
        this.cards = [];
        this.details = [];
      } else {
        if (!this.cards) {
          this.cards = [];
          this.details = [];
        }
        if (this.cards.length < this.numberOfCards) {
          this.details[0] = false;
          while (this.cards.length < this.numberOfCards) {
            this.cards.push(this.getNewCard());
            this.details.push(false);
          }
        } else if (this.cards.length > this.numberOfCards) {
          while (this.cards.length > this.numberOfCards) {
            this.cards = this.cards.slice(0, -1);
            this.details = this.details.slice(0, -1);
          }

          if (this.numberOfCards === 1) {
            this.details[0] = true;
          }
        }
      }
    },
  },
  created() {
    this.numberOfCards =
      this.cards && this.cards.length ? this.cards.length : 1;
  },
  mounted: function () {
    this.$nextTick(function () {
      // If there is no request id then the page was refreshed and the data is lost
      // Go back to contacts page
      if (!this.requestid) {
        this.$store.dispatch("rebuild");
        this.$forceUpdate();

        if (!this.requestid) {
          this.$router.replace("contact");
          return;
        }
      }

      this.loading = true;
      limitService
        .getLimits(this.companyType)
        .then((data) => {
          this.limits = data.limits;
          if (!this.defaultcard || !this.defaultcard.LimitSet) {
            this.defaultcard.LimitSet = data.defaultLimit;
          }
          this.defaultcard.NewCustomerRequestId = this.requestid;
        })
        .catch((err) => {
          console.log("Fetching limits data failed.");
          console.log(err);
        })
        .finally(() => {
          productService
            .getProducts(this.companyType)
            .then((products) => {
              this.products = products;
            })
            .catch((err) => {
              console.log("Fetching products data failed.");
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
              this.handleDefaultCardChange();
              this.handleCardNumberChange();
            });
        });

      cardService.getNumberOfCards(this.companyType).then((data) => {
        this.mincards = data.minCards;
        this.maxcards = data.maxCards;
        this.contactThreshold = this.settings.CardOptionsContactThreshold;

        this.cardMaxamountText = StringUtils.format(
          cardMaxamountText[this.$i18n.locale],
          { maxcards: this.contactThreshold - 1 }
        );

        this.cardMaxamountNoshorthandText = StringUtils.format(
          cardMaxamountNoshorthandText[this.$i18n.locale],
          { maxcards: this.maxcards }
        );

        if (this.numberOfCards < data.minCards) {
          this.cardNumberChanged(data.minCards);
        }
      });

      locationService.getLocations().then((data) => {
        this.locations = data;
      });
    });
  },
};
</script>