<template>
  <div>
    <v-select
      v-model="currentNumber"
      :items="cardSelection"
      item-text="name"
      item-value="number"
    >
    </v-select>
  </div>
</template>

<script>
import StringUtils from "../../util/stringutils";
export default {
  name: "card-selector",
  data() {
    return {
      currentNumber: 0,
    };
  },
  props: {
    mincards: Number,
    maxcards: Number,
    initial: Number,
    companytype: String,
  },
  watch: {
    currentNumber() {
      this.$emit("numberchanged", this.currentNumber);
    },
    initial() {
      this.currentNumber = this.initial;
    },
  },
  computed: {
    cardSelection() {
      const cards = [];

      for (let i = this.mincards; i <= this.maxcards; i++) {
        cards.push({
          name: `${i} ${
            i === 1
              ? this.$t("Card").toLowerCase()
              : this.$t("Cards").toLowerCase()
          }`,
          number: i,
        });
      }

      if (this.companytype === "company")
      {
        cards.push({
          name: StringUtils.cformat(this.$t("maxcard"), this.maxcards),
          number: this.maxcards + 1,
        });
      }

      return cards;
    },
  },
  created() {
    this.currentNumber = this.initial;
  },
};
</script>